import React, { useState, useEffect } from 'react';
import { withNavbar } from '../Navbar';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { v2UIRoutePath } from '../../../common/commons';
import RequestQueue from './RequestQueue';
import DailyFeed from './DailyFeed';
import CPSHealthCheck from './CPSHealthCheck';
import BulkUpload from './BulkUpload';
import RoutingValidator from './RoutingValidator';

const useStyles = makeStyles(() => ({
    homePageWrapper: {
        fontSize: '0.8rem',
        height: "100%",
        margin: "0 10px 0px 10px"
    },
    adminTabWrapper: {
        '& .MuiGrid-item': {
            background: '#f8f9fa',
        },
        background: '#f8f9fa',
        boxShadow: "0px 0px 0.8px 0px rgb(0 0 0 / 40%)",
        height: "calc( 100% - 40px)"
    },
    headerTitle: {
        textAlign: "center",
        flexGrow: 1,
        fontWeight: "bold",
        marginTop: '20px',
        marginBottom: '14px'
    },
    verticalTabGridItem: {
        maxWidth: '180px',
    },
    verticalTabGridContent: {
        maxWidth: 'calc( 100% - 180px)',
    },
    verticalTabWrapper: {
        borderRight: "1px solid #dedfdf",
        margin: "0px 10px 10px 0px",
        padding: "10px",
        height: '100%',
        '& .MuiTab-wrapper': {
            alignItems: 'flex-start',
            textTransform: 'capitalize'
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginBottom: '5px',
            outline: '0px',
            minHeight: '35px',
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            background: '#7f9ed7',
            width: '4px',
            height: '35px',
        }
    },
    appsScopeWrapper: {
        '& .MuiTabs-root': {
            minHeight: '35px'
        },
        '& .scopeHeader': {
            padding: '10px 0px 5px 10px',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderBottom: '1px solid #dedfdf',
            marginBottom: '5px',
            marginRight: '10px',
            '& a': {
                color: '#212529'
            }
        },
        '& .MuiTab-root.Mui-selected': {
            background: "white",
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginRight: '5px',
            outline: '0px',
            minHeight: '35px',
            '& .MuiTab-wrapper': {
                fontWeight: 'bold',
            },
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            height: '3px'
        }
    }
}));
// TabHistoryPaths Variable Holds all the tab paths available in the Tracking Screen
const TabHistoryPaths = [
    "bulkupload",
    "cpshealthcheck",
    "dailyfeed",
    "requestqueue",
    "routingvalidator"
];

function Tracking(props) {
    const classes = useStyles();
    const [verticalTab, setVerticalTab] = useState(0);
    const { routerProps } = props
    const { history, match } = routerProps;
    useEffect(() => {
        if (match?.params?.page) {
            setVerticalTab(TabHistoryPaths.indexOf(match?.params?.page));
        }
    }, [match])
    const activeTabName = TabHistoryPaths[verticalTab];
    return (
        <div className={`pl-2 pr-2 ${classes.homePageWrapper}`}>
            <Typography variant="h6" className={classes.headerTitle}>
                Requests Tracking
            </Typography>
            <Grid container className={classes.adminTabWrapper}>
                <Grid item xs className={classes.verticalTabGridItem}>
                    <div className={classes.verticalTabWrapper}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={verticalTab}
                            onChange={(_event, newValue) => {
                                setVerticalTab(newValue);
                                const path = TabHistoryPaths[newValue];
                                history.push(`${v2UIRoutePath}tracking/${path}`)
                            }}
                            aria-label="Vertical tabs"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Bulk Upload" />
                            <Tab label="CPS Health Check" />
                            <Tab label="Daily Feed" />
                            <Tab label="Request Queue" />
                            <Tab label="Routing Validator" />
                        </Tabs>
                    </div>

                </Grid>
                <Grid item xs className={classes.verticalTabGridContent}>
                    <div className={classes.appsScopeWrapper}>
                        {activeTabName === "requestqueue" && (
                            <RequestQueue />
                        )}
                        {activeTabName === "dailyfeed" && (
                            <DailyFeed />
                        )}
                        {activeTabName === "bulkupload" && (
                            <BulkUpload />
                        )}
                        {activeTabName === "cpshealthcheck" && (
                            <CPSHealthCheck />
                        )}
                        {activeTabName === "routingvalidator" && (
                            <RoutingValidator />
                        )}
                        

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default withNavbar(React.memo(Tracking), 1);
