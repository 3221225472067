import * as React from "react";
import {
  GridApiContext,
} from "@mui/x-data-grid";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { IconButton, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles(
  {
    root : {
      height: '35px',
      display: 'flex',
      justifyContent: 'space-between'
    },
    left : {
      lineHeight: '35px',
      marginLeft: '10px'
    },
    right: {
      textAlign: "right",
      marginRight: '15px',
      '& .MuiButtonBase-root': {
        padding: '5px !important'
      }
    }

  }
);

export default function Toolbar({
  addIconLabel,
  downloadLabel,
  handleDownloadGrid,
  rows,
  isAddIconDisabled = false,
  handleNoRowDownloadDisabled = false,
  defaultValuesForNewRecord = {},
  leftContent
}) {
  const classes = useStyles();
  const apiRef = React.useContext(GridApiContext);

  const handleAddNewRecord = () => {
    let editExists = false;
    const currentEditIDs = Object.keys(apiRef.current.getEditRowsModel());
    currentEditIDs.forEach((_editId) => {
      editExists = true;
    });
    if (editExists) {
      return false;
    }

    const id = 'editNewRecord-' + Math.floor(Math.random() * 10);
    apiRef.current.updateRows([{ id, isNew: true, isNewRecord: true, is_active: "False", ...defaultValuesForNewRecord }]);
    apiRef.current.setRowMode(id, 'edit');

    setTimeout(() => {
      // eslint-disable-next-line 
      if (apiRef && apiRef.current && apiRef.current.scrollToIndexes){
          apiRef.current.scrollToIndexes({
        rowIndex: 0
      })
      }
  }, 150);
  }

  let isDownloadButtonDisabled = false;
  if (handleNoRowDownloadDisabled && rows.length == 0) {
    isDownloadButtonDisabled = true;
  }
  return (
    <div className={classes.root}>
    <div className={classes.left}>
      {leftContent && leftContent()}
    </div>
    <div className={classes.right} >
      {addIconLabel && (<Tooltip title={addIconLabel}>
        <IconButton
          disabled={isAddIconDisabled}
          onClick={handleAddNewRecord}
          aria-label="add">
          <AddCircleIcon />
        </IconButton>
      </Tooltip>)}
      {downloadLabel && (
        <Tooltip title={downloadLabel}>
          <IconButton
            disabled={isDownloadButtonDisabled}
            onClick={() => {
              handleDownloadGrid(rows)
            }}
            aria-label="download"
            data-testid={"download"}
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
    </div>
  );
}
